'use strict';
var base = require('base/login/login');
var formValidation = require('../components/formValidation');
var createErrorNotification = require('base/components/errorNotification');
var b64 = require('../utils/base64');

module.exports = {
    login: function () {
        $('form.login').submit(function (e) {
            var form = $(this);
            var formSerializeArray = form.serializeArray();
            var formData = {};
            var heap = heap || window.heap;

            $(formSerializeArray).each(function (i, field) {
                formData[field.name] = field.value;
            });

            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.login').trigger('login:submit', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                        $('form.login').trigger('login:error', data);
                    } else {
                        if (typeof formData.loginEmail !== 'undefined' && formData.loginEmail !== null) {
                            if (typeof heap !== 'undefined' && heap != undefined) {
                                heap.identify(formData.loginEmail);
                            }
                        }
                        $('form.login').trigger('login:success', data);
                        location.href = data.redirectUrl;
                    }
                },
                error: function (data) {
                    if (data.responseJSON && data.responseJSON.redirectUrl) {
                        window.location.href = data.responseJSON.redirectUrl;
                    } else {
                        $('form.login').trigger('login:error', data);
                    }
                },
                complete: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    register: function () {
        $('form.registration').submit(function (e) {
            var form = $(this);
            e.preventDefault();
            var url = form.attr('action');
            form.spinner().start();
            $('form.registration').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        location.href = data.redirectUrl;
                    }
                },
                error: function (err) {
                    if (err.responseJSON && err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification($('.error-messaging'),
                            err.responseJSON
                                ? err.responseJSON.errorMessage
                                : 'Something went wrong. Please try again and if the issue persists, please contact customer support.'
                        );
                    }
                },
                complete: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    resetPassword: function () {
        $('.reset-password-form').submit(function (e) {
            var form = $(this);
            var receivedMsgHeading = form.attr('header');
            var receivedMsgBody = form.attr('desc');
            e.preventDefault();
            var url = form.attr('action');
            var label = form.attr('label');
            form.spinner().start();
            $('.reset-password-form').trigger('login:register', e);
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        formValidation(form, data);
                    } else {
                        $('.request-password-title').text(receivedMsgHeading);
                        $('.request-password-body')
                            .empty()
                            .append('<div class="card-content">' + receivedMsgBody + '</div>');
                        if (!data.mobile) {
                            $('#submitEmailButton')
                                .text(label)
                                .attr('data-dismiss', 'modal');
                        } else {
                            $('.send-email-btn')
                                .empty()
                                .html('<a href="' + data.returnUrl + '" class="btn button-secondary-invert btn-block">' + label + '</a>');
                        }
                    }
                },
                complete: function () {
                    form.spinner().stop();
                }
            });
            return false;
        });
    },

    clearResetForm: base.clearResetForm,
    sendTrackOrder: function () {
        var $trackForm = $('.login-page form.trackorder');
        if ($trackForm.length > 0) {
            $trackForm.submit(function (e) {
                e.preventDefault();
                var $this = $(this);
                var params = {};
                var orderID = $('#trackorder-form-number').val();
                var email = $('#trackorder-form-email').val();
                var zip = $('#trackorder-form-zip').val();

                if (orderID) {
                    params.trackOrderNumber = orderID;
                }

                if (email) {
                    params.trackOrderEmail = email;
                }

                if (zip) {
                    params.trackOrderPostal = zip;
                }

                params = JSON.stringify(params);
                params = b64.encode(params);
                var cURL = $this.data('url');
                var url = new URL(cURL);
                var searchParams = url.searchParams;
                searchParams.set('p', params);

                url.search = searchParams.toString();
                // redirect form with encoded parameter
                window.location = url.toString();
            });
        }
    }
};
